import { FreeTrialSecrets } from '@pages/go-to-checkout';

// TODO: DEFINE HTML LINTER CONFIG
export const HTMLHintConfig = {
  'tag-pair': true,
  'tagname-lowercase': true,
  'id-unique': true,
  'attr-lowercase': true,
  'attr-no-duplication': true,
  'attr-no-unnecessary-whitespace': true,
  'attr-unsafe-chars': true,
  'attr-value-double-quotes': true,
  'attr-value-not-empty': false,
  'alt-require': false,
  'input-requires-label': false,
};

// TODO: DEFINE JSHINT LINTER CONFIG
export const JSHINTConfig = {
  esversion: 11,
};

export const LESSON_FEEDBACK_BLOCK_ELEMENT = 'lesson-feedback-block';
export const LESSON_INSTRUCTIONS_BLOCK_ELEMENT = 'lesson-instructions-block';
export const LESSON_INSTRUCTIONS_DATABASE_SOURCE_ELEMENT =
  'lesson-instructions-database-source';
export const LESSON_FTG_SOLUTION_ITEMS_BLOCK_ELEMENT =
  'lesson-ftg-solutions-block';
export const LESSON_EDITOR_BLOCK_ELEMENT = 'mimo-editor-block';

export const NAVBAR_HEIGHT = 48;

export const WEB_TRACK_FULLSTACK_ID = 50;
export const PYTHON_TRACK_ID = 125;
export const BACKEND_TRACK_ID = 236;
export const SQL_TRACK_ID = 145;
export const HTML_TRACK_ID = 194;
export const JAVASCRIPT_TRACK_ID = 197;
export const SWIFT_TRACK_ID = 214;
export const WEB_TRACK_ID = 219;
export const CSS_TRACK_ID = 225;
export const PYTHON_DEVELOPER_TRACK_ID = 226;
export const TYPESCRIPT_TRACK_ID = 228;

type WEB_TRACK_FULLSTACK_ID_TYPE = 50;
type PYTHON_TRACK_ID_TYPE = 125;
type SQL_TRACK_ID_TYPE = 145;
type HTML_TRACK_ID_TYPE = 194;
type JAVASCRIPT_TRACK_ID_TYPE = 197;
type SWIFT_TRACK_ID_TYPE = 214;
type WEB_TRACK_ID_TYPE = 219;
type CSS_TRACK_ID_TYPE = 225;
type PYTHON_DEVELOPER_TRACK_ID_TYPE = 226;
type BACKEND_TRACK_ID_TYPE = 236;
type TYPESCRIPT_TRACK_ID_TYPE = 228;

export type CareerTrackId =
  | WEB_TRACK_FULLSTACK_ID_TYPE
  | PYTHON_DEVELOPER_TRACK_ID_TYPE
  | BACKEND_TRACK_ID_TYPE
  | WEB_TRACK_ID_TYPE;
export type LanguageTrackId =
  | PYTHON_TRACK_ID_TYPE
  | SQL_TRACK_ID_TYPE
  | HTML_TRACK_ID_TYPE
  | JAVASCRIPT_TRACK_ID_TYPE
  | SWIFT_TRACK_ID_TYPE
  | CSS_TRACK_ID_TYPE
  | TYPESCRIPT_TRACK_ID_TYPE;
export type TrackId = CareerTrackId | LanguageTrackId;

export const SUPPORTED_CAREER_TRACK_IDS: CareerTrackId[] = [
  WEB_TRACK_ID,
  WEB_TRACK_FULLSTACK_ID,
  PYTHON_DEVELOPER_TRACK_ID,
  BACKEND_TRACK_ID,
];
export const SUPPORTED_LANGUAGE_TRACK_IDS: LanguageTrackId[] = [
  SQL_TRACK_ID,
  HTML_TRACK_ID,
  JAVASCRIPT_TRACK_ID,
  SWIFT_TRACK_ID,
  CSS_TRACK_ID,
  PYTHON_TRACK_ID,
  TYPESCRIPT_TRACK_ID,
];

export const SUPPORTED_TRACK_IDS = [
  ...SUPPORTED_CAREER_TRACK_IDS,
  ...SUPPORTED_LANGUAGE_TRACK_IDS,
];

// indexdb lesson progress
export const LESSON_PROGRESS_DB_NAME = 'MimoLessonProgress';
export const LESSON_PROGRESS_DB_VERSION = 1;
export const LESSON_PROGRESS_DB_STORE_NAME = 'LessonProgressStore';

// section details page
export const APPLY_SOLUTION_MODAL_QUERY_PARAM = 'confirmDialog';
export const SECTION_DETAILS_MODAL_QUERY_PARAM = 'showModal';
export const SECTION_DETAILS_PROJECT_MODAL_QUERY_PARAM = 'showProjectModal';
export const SECTION_DETAILS_PROJECT_MODAL_FULLSCREEN_QUERY_PARAM =
  'showProjectModalFullscreen';

// path overview drawer
export const OVERVIEW_DRAWER_QUERY_PARAM = 'showDrawer';

// identifiers
export const NEXT_LEARN_PATH_CARD_ID = 'next-learn-path-card';
export const CHALLENGE_CARD_ID = 'challenge-card';
export const NEXT_SECTION_CARD_ID = 'next-section-card';

// android & ios deep linking
export const ANDROID_INTENT_URL =
  'intent://getmimo.com/login#Intent;scheme=mimo;package=com.getmimo;S.browser_fallback_url=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.getmimo;end';
export const IOS_INTENT_URL = 'https://getmimo.com/learn';

export const hotjarId = 2939875;

export const MAX_CONSOLE_OUTPUT_LENGTH = 170000;

export const MAX_FREE_PROJECT_COUNT = 10;
export const MAX_MACHINE_COUNT = 10;

// projects modal query param
export const PROJECTS_UPGRADE_MODAL_QUERY_PARAM = 'showProjectsUpgradeModal';

export const OPTIONAL_TASKS_FOR_GUIDED_PROJECTS: {
  [key: number]: string[];
} = {
  1516: [
    'Order by `rating` first and then by `name`',
    "Select all restaurants that are either `Japanese'` or `'Italian'`",
  ],
  2088: [
    'Write another query to see which books are not in the `orders` table',
    'Compare the `stock` and `copies_ordered` tables to see if a book is in stock',
  ],
  2589: [
    'Add a Fahrenheit to Celsius converter',
    'Style the page to your liking',
    'Create two buttons for unidirectional conversions',
  ],
  2595: [
    'Add a few more list items with your favorite drinks or snacks',
    "Nest an unordered list with 'small' and 'large' items for size options",
    'Add an image in the first div as well; make it have rounding their corners',
  ],
  2596: [
    'Link to your CSS file and style the game',
    'Add a button to restart the game',
    'Let the player choose the random number range before starting the game',
  ],
  2597: [
    'Give the items a border and adjust the padding',
    "Add a border to the modal's content",
    'Use a pointer cursor on hover to show images are clickable',
  ],
  2604: [
    'Add box-shadow to the card',
    'Add an animation to the card on hover',
    'Make the image zoom in when you hover over the image',
  ],
  2608: [
    'Add a button to retry if one gets the answer wrong',
    'Add more questions',
    'Always display the current score instead of only showing it at the end',
  ],
  2611: [
    'Look into Font Awesome and replace the bot icon with another one',
    'Give the bot more options to respond when it runs out of jokes',
    'Automatically scroll to the bottom of the web page if a new text is added',
  ],
  2612: [
    'Implement a way to decipher an encrypted text',
    'Add another cipher method and let the user select which version to use',
    'Update the design to make it look like a tool for spies',
  ],
  2614: [
    'Play around with `setInterval` and set it to a higher amount to make the game easier',
    'Keep stock of the treats',
    "Disable a `treat` when the number is 0 and style it accordingly if it's unavailable",
    'Add a timer to see how long the player could keep their pets',
  ],
  2616: [
    'Handle errors the API might return',
    'Update the design to display a grid of images',
    'Make the images clickable to display the information for that character',
  ],
  2617: [
    'Add more sections to the page and more details about yourself',
    'Add all your existing projects',
    'Add more skills to the skills section',
  ],
  2618: [
    'Add design via your `index.css` file',
    'Try out the font `Press Start 2P` from Google Fonts',
    'Alter the query parameters to get more than just 20 Pokemon back',
    'Load the detailed info of a Pokemon when selecting one in the Pokedex and display the details',
  ],
  2627: [
    'Enhance the quote structure by including additional fields like `author` and `year`',
    'Add another endpoint to return the available categories, not the jokes in a category',
    'Change the `/quotes/:category` route so it returns a random quote from the passed category',
  ],
};

// old path effect classes
export const buttonPressEffectClasses =
  'hover:mt-[6px] hover:h-[calc(100%-6px)] hover:place-self-end hover:pb-[10px] active:mt-[12px] active:h-[calc(100%-12px)] active:pb-1';

export const TOOLTIP_DELAY_SHOW = 2000;
export const APPSTORE_BUTTONS_ADJUST_LINK_SIGNUP_FLOW =
  'https://app.adjust.com/1bbdlllf';
export const APPSTORE_BUTTONS_ADJUST_LINK = 'https://app.adjust.com/17q6483l';

export const FACEBOOK_LINK = 'https://www.facebook.com/mimo';
export const INSTAGRAM_LINK = 'https://instagram.com/getmimo';
export const TWITTER_LINK = 'https://twitter.com/getmimo';
export const LINKEDIN_LINK = 'https://www.linkedin.com/company/mimo';
export const MEDIUM_LINK = 'https://medium.com/getmimo';
export const TIKTOK_LINK = 'https://www.tiktok.com/@mimo_org';

export const USER_SETTINGS_AUDIO_KEY = 'Enable sound';

export const ABOUT_US_LINK = 'https://jobs.mimo.org';
export const JOBS_LINK = 'https://jobs.mimo.org/#jobs-1d547bc0';

export const freeTrialQueryObject: { 'ft-meta': FreeTrialSecrets } = {
  'ft-meta': '83nvs',
};

export const MIMO_PRO_TO_MAX_DISCOUNT_PERCENT = 25;
